<template>
<div :class="'elist dirr ' + $store.state.direction" >
<v-layout row wrap justify-center>
    <v-flex sm4 xs12 md3 lg3 class="elistDes">
        <div class="title">
            <span></span>
            <p class="tl">{{ $t("email_list") }}</p>
        </div>
        <p class="tl">{{ $t("to_al_Kafeel_net") }}</p>
    </v-flex>
    <v-flex sm4 xs9 md6 lg6 class="alistForm alistfeild">
        <v-form ref="form" v-model="valid">
            <v-text-field @keyup.enter.native="send()" onkeypress="return event.keyCode != 13" v-model="i.email" solo class="dirr" full-width :label="$t('enter_your_email')" :rules="[rules.required, rules.email]"></v-text-field>
        </v-form>
    </v-flex>
    <v-flex sm4 xs3 md3 lg3 class="alistForm">
        <v-btn height="56" class="ma-2 tl alistbtn" :loading="loading" color="rgba(177, 189, 82, 1)" @click="send()">
            <p class="alistSub tl">{{ $t("subscribe") }}</p>
        </v-btn>
    </v-flex>
</v-layout>
</div>
</template>


<script>

import Swal from "sweetalert2";
export default {
    name: "elist",
    data() {
        return {
            i:{email:''},
            loader: null,
            loading: false,
            valid: false,
            rules: {
                required: value => !!value || this.$t("required_field"),
                email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
            },
        }
    },
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      },
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
    },
    created () {
    },
    methods:{
        send() {
            if (this.$refs.form.validate()) {
                this.loader = 'loading';
                this.$axios.post('postal_bulletin/add', {
                    email: this.i.email,
                }).then(this.i = {},
                    Swal.fire({
                        title: this.$t("elist_email_sucees"),
                        text: "",
                        icon: "success",
                        timer: 3000,
                        confirmButtonColor: '#B1BD52',
                    })
                )
            }
        },
    },
}
</script>